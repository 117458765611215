import React, { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { useAuth } from '../context/AuthContext';
const links = [
  { name: 'Home', href: '/' },
  { name: 'Services', href: '/services' },
  { name: 'Experiences', href: '/experiences' },
  { name: 'Redeem Code', href: '/redeem' },
  { name: 'Talent', href: '/talent' },
  { name: 'Jobs', href: '/jobs' },
  { name: 'About', href: '/about' },
  { name: 'Contact', href: '/contact' },
];
const HeaderComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { authLoading, currentUser, logout } = useAuth();
  if (authLoading) {
    return null;
  }
  return (
    <>
      {/* Main Header */}
      <header className="w-full bg-[#00203F] py-4 text-white flex justify-between items-center px-4">
        {/* Logo/Text */}
        <div className="text-title text-lg font-bold">Nautical Nonsense</div>

        {/* Hamburger Button */}
        <button onClick={() => setIsOpen(true)} className="text-title focus:outline-none text-2xl">
          &#9776; {/* Hamburger Icon */}
        </button>
      </header>
      {/* Drawer Menu */}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-40 flex justify-end">
        <DialogPanel className="w-64 bg-header p-4 shadow-lg">
          {/* Close Button */}
          <div className="flex justify-end">
            <button onClick={() => setIsOpen(false)} className="text-title text-2xl mb-4 focus:outline-none">
              &times; {/* Close X Icon */}
            </button>
          </div>
          {/* Drawer Links */}
          <div className='mt-6 border-t pt-4'>
            <ul>
              {links.map((link) => (
                <li key={link.name} className="mb-2">
                  <a href={link.href} className="text-title hover:text-blue-800">
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
         </div>
          {/* Login/Logout Link */}
          <div className="mt-6 border-t pt-4">
            {currentUser ? (
              <button onClick={logout} className="text-title hover:text-red-800 w-full text-left">
                Logout
              </button>
            ) : (
              <a href="/login" className="text-title hover:text-blue-800 w-full block text-left">
                Login
              </a>
            )}
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
};
export default HeaderComponent;
import React from 'react';

const ExperiencesPage = () => {
  return (
    <div className="min-h-screen bg-[#00203F] text-white px-4 py-16">
      <div className="w-full max-w-4xl mx-auto bg-white text-black p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-6 text-center" style={{ fontFamily: "'Shadows Into Light', cursive" }}>
          Our Experiences
        </h1>
        <p className="text-lg text-center mb-8">
          Nautical Nonsense offers a wide range of immersive and entertaining experiences. Whether you’re looking to set sail on a pirate adventure, dive into mermaid lore, or simply enjoy a night of nautical fun, we have something for everyone. Explore the adventures that await you!
        </p>

        {/* Pirate Adventure Section */}
        <div className="bg-[#f8f9fa] p-6 mb-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Pirate Adventure</h2>
          <p className="text-lg">
            Hoist the Jolly Roger and join us for a thrilling pirate adventure filled with treasure hunts, sword fights, and seafaring fun!
          </p>
          <p className="text-xl font-semibold mt-4">Coming Soon...</p>
        </div>

        {/* Mermaid Encounter Section */}
        <div className="bg-[#f8f9fa] p-6 mb-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Mermaid Encounter</h2>
          <p className="text-lg">
            Meet the mystical mermaids of the deep and dive into their enchanting world. Perfect for those who dream of the sea!
          </p>
          <p className="text-xl font-semibold mt-4">Coming Soon...</p>
        </div>

        {/* Karaoke Nights Section */}
        <div className="bg-[#f8f9fa] p-6 mb-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Karaoke Nights</h2>
          <p className="text-lg">
            Sing your heart out at our lively karaoke nights, where the stage is yours and the audience is ready for some nautical nonsense!
          </p>
          <p className="text-xl font-semibold mt-4">Coming Soon...</p>
        </div>

        {/* Historical Voyages Section */}
        <div className="bg-[#f8f9fa] p-6 mb-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Historical Voyages</h2>
          <p className="text-lg">
            Step back in time and explore the rich history of the seas with our educational and entertaining historical voyages.
          </p>
          <p className="text-xl font-semibold mt-4">Coming Soon...</p>
        </div>

        {/* Spooky Cruises Section */}
        <div className="bg-[#f8f9fa] p-6 mb-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Spooky Cruises</h2>
          <p className="text-lg">
            Dare to embark on a spine-chilling cruise that blends ghost stories and eerie encounters with a touch of nautical fear!
          </p>
          <p className="text-xl font-semibold mt-4">Coming Soon...</p>
        </div>
        
        {/* Captain Jack Sparrow Experience Section */}
        <div className="bg-[#f8f9fa] p-6 mb-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Captain Jack Sparrow Experience</h2>
          <p className="text-lg">
            Join the legendary Captain Jack Sparrow on an unforgettable adventure filled with mischief, mayhem, and rum!
          </p>
          <p className="text-xl font-semibold mt-4">Coming Soon...</p>
        </div>
        
      </div>
    </div>
  );
};

export default ExperiencesPage;

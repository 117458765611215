const HomePage = () => {
  return (
    <>
      <div className="relative min-h-screen overflow-hidden">
        {/* Parallax Background */}
        <div
          className="absolute inset-0 bg-cover bg-center parallax-bg"
          style={{ backgroundImage: 'url(/parallax-bg.webp)' }}
        ></div>
        {/* Content */}
        <div className="relative z-10 flex flex-col px-4 pt-4 pb-12 text-white text-center space-y-8">
          <div className="text-title p-8 bg-white bg-opacity-95 shadow-lg rounded-lg">
            <h1 className="text-6xl font-bold mb-4 text-font" style={{ fontFamily: "'Shadows Into Light', cursive" }}>
              Nautical Nonsense
            </h1>
            <p className="text-2xl mb-6 text-font">Because It's Fun</p>
            <p className="text-xl mb-6 text-font">
  We're currently enhancing our website to provide you with an even better experience. Thank you for your patience!
</p>

          </div>
          {/* About Section */}
          <div className="p-8 bg-white bg-opacity-95 shadow-lg rounded-lg text-black">
            <h2 className="text-4xl font-bold mb-4 text-font">About Nautical Nonsense</h2>
            <p className="text-lg mb-4">
              Nautical Nonsense is an entertainment company dedicated to bringing joy, laughter, and a sense of adventure to people of all ages. We believe that life is too short to be taken too seriously, and our mission is to create unforgettable experiences that capture the whimsical spirit of the high seas. Whether it's through interactive tours, themed events, or unique performances, we are all about embracing the fun and making sure our guests leave with smiles on their faces.
            </p>
            <p className="text-lg">
              From swashbuckling pirate adventures to lighthearted nautical escapades, Nautical Nonsense offers a variety of immersive experiences designed to entertain and delight. Our team of passionate performers and storytellers are committed to spreading cheer and making every event a memorable occasion. So come aboard and join us on a journey where the only rule is to have a great time, because at Nautical Nonsense, it's all about the fun!
            </p>
          </div>
          {/* Treasure Hunt Section */}
          <div className="p-8 bg-white bg-opacity-95 shadow-lg rounded-lg text-black">
            <h2 className="text-4xl font-bold mb-4 text-font">Treasure Hunt</h2>
            <p className="text-lg mb-6">
              All over town, we've hidden hints, clues, and treasures waiting to be found! From secret codes to mysterious items, embark on a thrilling treasure hunt and uncover the hidden gems of Nautical Nonsense. Each find brings you closer to a unique reward that can be redeemed for exciting prizes and experiences. Are you ready to set sail on the adventure of a lifetime?
            </p>
            <p className="text-2xl mb-4 font-semibold">Found a Treasure Code?</p>
            <button className="bg-accent text-white px-6 py-3 rounded-md text-lg hover:bg-opacity-80">
              Redeem Now
            </button>
          </div>
          {/* Featured Adventures Section */}
          <div className="p-8 bg-white bg-opacity-95 shadow-lg rounded-lg text-black">
            <h2 className="text-4xl font-bold mb-4 text-font">Featured Adventures</h2>
            <p className="text-lg mb-6">
              Exciting new adventures are on the horizon! Our featured experiences are coming soon, with unique, fun-filled activities that will make you feel like a true adventurer. Stay tuned for more details as we prepare to launch our unforgettable journeys into the unknown!
            </p>
            <p className="text-2xl mb-4 font-semibold">Coming Soon...</p>
          </div>
          {/* Partners Section */}
          <div className="p-8 bg-white bg-opacity-95 shadow-lg rounded-lg text-black">
            <h2 className="text-4xl font-bold mb-4 text-font">Partners</h2>
            <p className="text-lg mb-6">
              We’re teaming up with some amazing local businesses and organizations to bring you even more fun and excitement. Our partners are just as passionate about creating memorable experiences as we are. Check back soon to see who’s joining the Nautical Nonsense crew!
            </p>
            <p className="text-2xl mb-4 font-semibold">Coming Soon...</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;

import React from 'react';

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-[#00203F] text-white px-8 py-16">
      <div className="max-w-4xl mx-auto bg-white text-black p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-6 text-center" style={{ fontFamily: "'Shadows Into Light', cursive" }}>
          About Nautical Nonsense
        </h1>
        <p className="text-lg mb-6">
          Nautical Nonsense is an entertainment company dedicated to bringing joy, laughter, and a sense of adventure to people of all ages. We believe that life is too short to be taken too seriously, and our mission is to create unforgettable experiences that capture the whimsical spirit of the high seas. Whether it's through interactive tours, themed events, or unique performances, we are all about embracing the fun and making sure our guests leave with smiles on their faces.
        </p>
        <p className="text-lg mb-6">
          From swashbuckling pirate adventures to enchanting mermaid encounters, our offerings are as diverse as the ocean itself. Dive into a night of karaoke fun, journey through historical tales, or join Captain Jack Sparrow himself for a swashbuckling good time. For those seeking a thrill, our spooky experiences are sure to send shivers down your spine. And with our talented team of cosplayers, actors, and storytellers, we bring these worlds to life with stunning costumes, immersive decorations, and captivating itineraries.
        </p>
        <p className="text-lg mb-6">
          At Nautical Nonsense, we’re more than just entertainment—we’re a reminder that the world is a playground. In the hustle and bustle of daily life, it's easy to forget that no matter who you are, laughter and fun are essential. Our passionate team is here to help you rediscover that joy, to break free from the mundane, and to embrace the magic that’s all around us.
        </p>
        <p className="text-lg mb-6">
          So, come aboard and let’s sail together on an unforgettable journey. Whether you’re young or young at heart, there’s something for everyone to enjoy. At Nautical Nonsense, the only rule is to have a great time—because life is an adventure, and it's far too short to miss out on the fun.
        </p>
        <p className="text-lg text-center font-semibold mt-8">
          The world is full of wonder, and adventure is out there waiting for those who dare to look. So, what are you waiting for? Embrace the nonsense, and let’s make some unforgettable memories together.
        </p>
      </div>
    </div>
  );
};

export default AboutPage;

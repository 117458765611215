import React from 'react';
import { Link } from 'react-router-dom'; // If you're using react-router for navigation

const RegisterSuccess = () => {
  return (
    <div className="bg-white min-h-screen px-4 py-8">
      <div className="max-w-md mx-auto p-6 bg-green-100 border border-green-400 rounded-lg">
        <h1 className="text-2xl font-bold text-green-800 mb-4">Registration Successful!</h1>
        <p className="text-gray-700 mb-6">
          Thank you for registering. You can now log in to your account.
        </p>
        <div className="flex justify-end">
          <Link to="/login" className="text-blue-500 hover:underline">
            Go to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;

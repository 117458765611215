import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import { Outlet } from 'react-router-dom';
const Layout = () => {
  return (
    <div className="flex flex-col items-center min-h-screen bg-[#d4f1f4]">
      {/* Header */}
      <HeaderComponent />
      {/* Main Content Wrapper */}
      <main className="w-full max-w-[1200px] text-font">
        {/* Render nested routes */}
        <Outlet />
      </main>
      <FooterComponent />
    </div>
  );
};
export default Layout;

import React from 'react';

const TalentPage = () => {
  return (
    <div className="min-h-screen bg-[#00203F] text-white flex items-center justify-center px-4 py-16">
      <div className="w-full max-w-4xl bg-white text-black p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold mb-6" style={{ fontFamily: "'Shadows Into Light', cursive" }}>
          Our Talented Crew
        </h1>
        <p className="text-lg mb-6">
          The magic of Nautical Nonsense wouldn’t be possible without the dedication and creativity of our talented team. From swashbuckling pirates and enchanting mermaids to captivating storytellers and dynamic performers, our crew brings the spirit of adventure to life.
        </p>
        <p className="text-lg font-semibold mb-4">Stay tuned as we introduce the incredible individuals who make every experience unforgettable!</p>
        <p className="text-2xl font-bold">Coming Soon...</p>
      </div>
    </div>
  );
};

export default TalentPage;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./context/ProtectedRoute";
import HomePage from "./pages/HomePage";
import Layout from "./components/Layout";
import AboutPage from "./pages/AboutPage";
import ContactUs from "./pages/ContactPage";
import TalentPage from "./pages/TalentPage";
import ExperiencesPage from "./pages/ExperiencesPage";
import ServicesPage from "./pages/ServicesPage";
import JobsPage from "./pages/JobsPage";
import ApplyPage from "./pages/ApplyPage";
import RedeemPage from "./pages/RedeemPage";
import ApplicationSentPage from "./pages/ApplicationSentPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import RegisterSuccess from "./pages/RegisterSuccess";

import AccountPage from "./pages/AccountPage";

function App() {
  return (
    <AuthProvider>
    <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/talent" element={<TalentPage />} />
            <Route path="/experiences" element={<ExperiencesPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/jobs" element={<JobsPage />} />
            <Route path="/redeem" element={<RedeemPage />} />
            <Route path="/apply" element={<ApplyPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/register-success" element={<RegisterSuccess />} />

            <Route path="/apply-success" element={<ApplicationSentPage />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/account" element={<AccountPage />} />
            </Route>

          </Route>
        </Routes>
    </Router>
    </AuthProvider>
  );
}
export default App;
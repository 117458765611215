import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/Button';
import { TextField } from '../components/Fields';
import { useState, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { getFunctions, httpsCallable } from "firebase/functions"; 
// LoginPage component
const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { login } = useAuth();
  const navigate = useNavigate();
  const recaptcha = useRef();
  const functions = getFunctions();
  const validateCaptcha = httpsCallable(functions, 'validateCaptcha');
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const recaptchaValue = recaptcha.current.getValue();
    if (!recaptchaValue) {
      setError("Please complete the reCAPTCHA");
      setLoading(false);
      return;
    }
    // Call the validateCaptcha cloud function
    const captchaResult = await validateCaptcha({ token: recaptchaValue });
    if (!captchaResult.data.success) {
      setError("Invalid reCAPTCHA");
      setLoading(false);
      return;
    }
    try {
      await login(email, password, recaptchaValue);
      navigate("/account");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center py-6">
      <h1 className="text-2xl font-bold mb-6">Login</h1>
      <div className='w-full max-w-sm bg-white p-8 shadow-md rounded-lg'>
        <form onSubmit={handleLogin} className="mt-10 grid grid-cols-1 gap-y-8">
          <TextField label="Email" name="email" type="email" autoComplete="email" required onChange={(e) => setEmail(e.target.value)} disabled={loading} />
          <TextField label="Password" name="password" type="password" autoComplete="current-password" required onChange={(e) => setPassword(e.target.value)} disabled={loading} />
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={recaptcha} />
          {error && <p className="mt-4 text-red-600">{error}</p>} 
          <div>
            <Button type="submit" variant="solid" color="blue" className="w-full" disabled={loading}>
              <span>
                {loading ? "Signing in..." : "Sign in"} <span aria-hidden="true">&rarr;</span>
              </span>
            </Button>
          </div>
        </form>
        {/* "Need an Account?" Link */}
        <div className="mt-6 text-center">
          <p className="text-sm">
            Need an account? <Link to="/register" className="text-blue-500 hover:underline">Sign up</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;

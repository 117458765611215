import React, { useState } from 'react';

const RedeemPage = () => {
  const [code, setCode] = useState('');

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the code redemption logic here
    console.log('Code submitted:', code);
    // You can add additional logic like validation or API calls
    setCode(''); // Reset the input field
  };

  return (
    <div className="min-h-screen bg-[#00203F] text-white flex items-center justify-center px-4 py-16">
      <div className="w-full max-w-md bg-white text-black p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold mb-6" style={{ fontFamily: "'Shadows Into Light', cursive" }}>
          Redeem Your Treasure
        </h1>
        <p className="text-lg mb-6">
          Found a treasure code? Enter it below to redeem your reward! Each code unlocks a unique prize or experience, so be sure to check back often for more treasure hunts and hidden gems.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="code" className="block text-lg font-medium mb-1">
              Enter Your Code
            </label>
            <input
              type="text"
              id="code"
              name="code"
              value={code}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Your Treasure Code"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#00203F] text-white py-2 rounded-md font-medium text-lg hover:bg-[#003366] focus:outline-none"
          >
            Redeem Now
          </button>
        </form>
      </div>
    </div>
  );
};

export default RedeemPage;

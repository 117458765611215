// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Optional for Firestore
import { getAuth } from "firebase/auth"; // Optional for Authentication
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCzfrDpibjKLn2TSUbQDTAXu0tpmORbFVE",
  authDomain: "nonsense-9a6b2.firebaseapp.com",
  projectId: "nonsense-9a6b2",
  storageBucket: "nonsense-9a6b2.appspot.com",
  messagingSenderId: "590855819698",
  appId: "1:590855819698:web:fcf9cd130b120989af14ab"
};
// Initialize Firebase App
const app = initializeApp(firebaseConfig);
// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
// Connect to emulator if specified

if (process.env.REACT_APP_USE_EMULATOR === "true") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}
export { app, db, auth, functions };



const AccountPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen px-4 py-8">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">My Account</h1>
        
        {/* Account Information Section */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Account Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg border">
              <h3 className="text-gray-600 font-medium">Name</h3>
              <p className="text-gray-800">John Doe</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg border">
              <h3 className="text-gray-600 font-medium">Email</h3>
              <p className="text-gray-800">john.doe@example.com</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg border">
              <h3 className="text-gray-600 font-medium">Phone</h3>
              <p className="text-gray-800">+1 234 567 8901</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg border">
              <h3 className="text-gray-600 font-medium">Address</h3>
              <p className="text-gray-800">123 Main St, Springfield</p>
            </div>
          </div>
        </div>

        {/* Account Actions Section */}
        <div>
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Account Actions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600">
              Update Information
            </button>
            <button className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600">
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;

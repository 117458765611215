import React from 'react';

const ServicesPage = () => {
  return (
    <div className="min-h-screen bg-[#00203F] text-white px-4 py-16">
      <div className="w-full max-w-4xl mx-auto bg-white text-black p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-6 text-center" style={{ fontFamily: "'Shadows Into Light', cursive" }}>
          Our Services
        </h1>
        <p className="text-lg text-center mb-8">
          Nautical Nonsense offers a diverse range of services to suit every adventurer's needs. Whether you're looking to explore by land, sea, or enjoy a comfortable ride to your next destination, we have you covered. Discover the experiences that await you!
        </p>

        {/* Car Rides Section */}
        <div className="bg-[#f8f9fa] p-6 mb-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Car Rides</h2>
          <p className="text-lg">
            Enjoy a comfortable and entertaining ride to your destination with our themed car services. Our drivers are not only skilled but also love to entertain with stories, music, and a touch of nautical nonsense!
          </p>
          <p className="text-xl font-semibold mt-4">Coming Soon...</p>
        </div>

        {/* Boat Rides Section */}
        <div className="bg-[#f8f9fa] p-6 mb-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Boat Rides</h2>
          <p className="text-lg">
            Set sail on one of our exhilarating boat rides and explore the open waters. From sunset cruises to pirate adventures, our boat services are designed to offer unique and memorable experiences on the water.
          </p>
          <p className="text-xl font-semibold mt-4">Coming Soon...</p>
        </div>

        {/* Land-based Services Section */}
        <div className="bg-[#f8f9fa] p-6 mb-6 rounded-lg shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Land-based Services</h2>
          <p className="text-lg">
            Our land-based services include everything from event hosting to treasure hunts and personalized experiences. Whether it's a birthday party or a corporate event, we bring the fun and adventure to you!
          </p>
          <p className="text-xl font-semibold mt-4">Coming Soon...</p>
        </div>

      </div>
    </div>
  );
};

export default ServicesPage;

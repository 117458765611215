import React from 'react';

const FooterComponent = () => {
  return (
    <footer className="w-full bg-[#00203F] py-4 text-white flex flex-col items-center">
      <div className="text-center mb-4">
        <p className="text-sm">© 2024 Nautical Nonsense. All Rights Reserved.</p>
      </div>
      <div className="text-center">
        <p className="text-sm">Follow Us:</p>
        <div className="flex space-x-4 mt-2">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            Facebook
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400">
            Twitter
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-pink-500">
            Instagram
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;

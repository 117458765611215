import React, { useState, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import SpinnerComponent from '../components/SpinnerComponent';
const ApplyPage = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', phoneNumber: '', canReceiveTexts: false, instagramUsername: '', age: '', city: '', aboutYou: '', skills: '', additionalInfo: '', });
  const [error, setError] = useState('');
  const recaptchaRef = useRef();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const functions = getFunctions();
    const sendApplication = httpsCallable(functions, 'sendApplication');
    const validateCaptcha = httpsCallable(functions, 'validateCaptcha');
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setError("Please complete the reCAPTCHA");
      setLoading(false);
      return;
    }
    try {
      
      const captchaResult = await validateCaptcha({ token: recaptchaValue });
    if (!captchaResult.data.success) {
      setError("Invalid reCAPTCHA");
      setLoading(false);
      return;
    }
      await sendApplication({ ...formData, recaptcha: recaptchaValue }); // Send form data and reCAPTCHA token
      //recaptchaRef.current.reset(); // Reset reCAPTCHA
      navigate('/apply-success'); // Redirect to success page
    } catch (err) {
      setError('Failed to submit application.');
      console.error('Error submitting application:', err);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <SpinnerComponent />; // Show loading spinner while processing
  }
  return (
    <div className="min-h-screen bg-[#00203F] text-white px-4 py-16 flex items-center justify-center">
      <div className="w-full max-w-3xl bg-white text-black p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-6" style={{ fontFamily: "'Shadows Into Light', cursive" }}>
          Join Our Crew
        </h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block mb-1">* First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-1">* Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-1">* Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-1">* Phone Number</label>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-1">
              Can Receive Texts?
              <input
                type="checkbox"
                name="canReceiveTexts"
                checked={formData.canReceiveTexts}
                onChange={handleChange}
                className="ml-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-1">Instagram Username</label>
            <input
              type="text"
              name="instagramUsername"
              value={formData.instagramUsername}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-1">* Age</label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-1">* City</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block mb-1">* Tell Us About You</label>
            <textarea
              name="aboutYou"
              value={formData.aboutYou}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
              rows="4"
            />
          </div>
          <div>
            <label className="block mb-1">* Skills/Talents</label>
            <textarea
              name="skills"
              value={formData.skills}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded"
              rows="4"
            />
          </div>
          <div>
            <label className="block mb-1">Additional Information</label>
            <textarea
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
              rows="4"
            />
          </div>
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={recaptchaRef} />
          <button
            type="submit"
            className="bg-[#00203F] text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-[#003366] focus:outline-none"
          >
            Submit Application
          </button>
        </form>
      </div>
    </div>
  );
};
export default ApplyPage;

import React from 'react';
import { Link } from 'react-router-dom';

const ApplicationSentPage = () => {
  return (
    <div className="min-h-screen bg-[#00203F] text-white flex items-center justify-center px-4 py-16">
      <div className="w-full max-w-md bg-white text-black p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-3xl font-bold mb-6">Application Submitted!</h1>
        <p className="text-lg mb-4">
          Thank you for applying! Your application has been successfully submitted.
        </p>
        <p className="text-lg mb-6">
          We appreciate your interest in joining our crew and will get back to you soon!
        </p>
        <Link
          to="/"
          className="inline-block bg-[#00203F] text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-[#003366] focus:outline-none"
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ApplicationSentPage;

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const JobsPage = () => {
  return (
    <div className="min-h-screen bg-[#00203F] text-white px-4 py-16 flex items-center justify-center">
      <div className="w-full max-w-3xl bg-white text-black p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold mb-6" style={{ fontFamily: "'Shadows Into Light', cursive" }}>
          Join Our Crew
        </h1>
        <p className="text-lg mb-6">
          Are you ready to bring joy, laughter, and a sense of adventure to people of all ages? At Nautical Nonsense, we’re always looking for passionate, creative, and enthusiastic individuals to join our crew. Whether you’re a performer, guide, or just love making people smile, we’d love to hear from you!
        </p>
        <p className="text-lg mb-8">
          Embark on a career where every day is an adventure, and no two days are the same. We offer a fun and dynamic work environment where your creativity can truly shine. If you're ready to set sail with us, click the button below to apply!
        </p>
        {/* Updated to use Link for navigation */}
        <Link
          to="/apply" // Link to the /apply route
          className="inline-block bg-[#00203F] text-white px-6 py-3 rounded-md text-lg font-medium hover:bg-[#003366] focus:outline-none"
        >
          Apply Now
        </Link>
      </div>
    </div>
  );
};

export default JobsPage;
